
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  u ~ div .email-container {
      min-width: 320px !important;
  }
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
  u ~ div .email-container {
      min-width: 375px !important;
  }
}
/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
  u ~ div .email-container {
      min-width: 414px !important;
  }
}

.hr-heading{
text-align: left;
}

.hr-color {
color: red;
font-size:x-large;
font-weight: bold;
}

.image-hr {
background-image: url("../../../images/favicon-hr.png");
background-position: center;
background-repeat: no-repeat;

}
