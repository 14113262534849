body {
    font-family: sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.man_idcard {
    width: 270px;
    height: 400px;
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 6px #00000029;
    border: 1px solid;
    border-color: rgb(5, 5, 5);

}

.bg-images {
    /* background-image: 'images/cardfront.png'; */
    object-fit: contain;
    background-repeat: no-repeat;
    text-align: center;
}
.Name_emp {
    text-align: center;
    font-size: 20px;
    margin: 20px 0px 4px 0px;
    text-transform: uppercase;
    color:rgb(11, 11, 95)
}

.post {
    font-size: 13px;
    color:rgb(11, 11, 95);
    font-weight: bolder;
}

.logo_img {
    text-align: left;
    margin-left: 4px;
    margin-top: 3px;
}

.man_idcard {
    padding: 4px;
}

.Employee {
    font-size: 13px;
    margin-bottom: 10px;
    color:rgb(11, 11, 95);
}
.normal_btm_text {
    text-align: start;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.Blood_group {
    font-size: 13px;
    margin-bottom: 10px;
    color:rgb(11, 11, 95);
}


.file-wrapper {
    width: 110px;
    height: 110px;
    border: 1px solid rgb(8, 8, 8);
    position: relative;
    margin: auto;
    margin-top: 30px;
    border-radius: 50%;
    max-zoom: 50%;
}
.file-wrapper:after {
    content: '+';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: max-content;
    height: max-content;
    display: block;
    max-height: 85px;
    font-size: 30px;
    font-weight: bolder;
    color: rgb(17, 16, 16);
}
.close-btn {
    display: none;
}

/* input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 99999;
    cursor: pointer;
    left: 0px;
} */

.file-set {
    background-size: cover;
    background-repeat: no-repeat;
    color: transparent;
    padding: 5px;
    border-width: 0px;
}

/* .file-set:hover{
transition: all 0.5s ease-out;
filter:brightness(110%);
} */
.file-set:before {
    color: transparent;
}

.file-set:after {
    color: transparent;
}

.file-set .close-btn {
    position: absolute;
    width: 35px;
    height: 35px;
    display: block;
    background: #000;
    color: #fff;
    top: 0;
    right: 0;
    font-size: 25px;
    text-align: center;
    line-height: 1.5;
    cursor: pointer;
    opacity: 0.8;
}

.file-set>input {
    pointer-events: none;
}
.font_s{
    font-size: 14px;
}

.form-control{
border: 1px solid;
border-color: grey;
-webkit-box-shadow: none;
box-shadow: 5px 10px 18px #f7f4f4;
}
.LoaderItem{
    position: fixed;
    top: 50%;
    right: 50%;
    z-index: 9999;
}