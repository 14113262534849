
/* __________________________________________________________________ */
.card-profile .btn-just-icon.btn-raised,
.card-testimonial .btn-just-icon.btn-raised {
    margin-left: 6px;
    margin-right: 6px;
}

.card-profile .card-avatar-top,
.card-testimonial .card-avatar {
    max-width: 100px;
    max-height: 130px;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card-profile.card-plain .card-avatar-top,
.card-testimonial.card-plain .card-avatar {
    margin-top: 0;
}

.card-testimonial .card-avatar-top {
    max-width: 100px;
    max-height: 100px;
}


.card-profile .card-avatar-left,
.card-testimonial .card-avatar {
    max-width: 130px;
    max-height: 130px;
    margin: -50px auto 0;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: -50px;
    bottom: 90px;
}

.card-profile.card-plain .card-avatar-left,
.card-testimonial.card-plain .card-avatar {
    margin-top: 0;
}

.card-testimonial .card-avatar-left {
    max-width: 100px;
    max-height: 100px;
}

.card-testimonial .card-description {
    font-style: italic;
}

.card-caption {
    font-family: monospace;
}

.card-testimonial .icon {
    margin-top: 30px;
}

/* 
.ftr{
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
} */


.btn-profile-break{
    font-size: 15px;
    border: none;
    padding: 2px;
    font-family: monospace;
    color: #354b9c;
    font-weight: bolder;
}
.column-newAttendance{
  width: 100%;
  float: left;
  text-align: center;
}
.clock-1 {
  height: 50px;
  font-size:28px;
  font-family: sans-serif;
  display: flex;
  position: relative;
  /* background: green; */
  overflow: hidden;
}

.clock-1::before, .clock::after {
  content: '';
  width: 7ch;
  height: 6vh;
  /* background: linear-gradient(to top, transparent, black); */
  position: absolute;
  z-index: 2;
}

.clock-1::after {
  bottom: 0;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  /* background: linear-gradient(to bottom, transparent, black); */
}

.clock-1 > div {
  display: flex;
}

.tick-hidden {
  opacity: 0;
}

.move {
  animation: move linear 1s infinite;
}
.imgAttendance{
  width: 100px;
  height: 100px;
}
@keyframes move {
  from {
    transform: translateY(0vh);
  }
  to {
    transform: translateY(-20vh);
  }
}

.check{
  width: auto;
  padding: 10px;
  background-color: rgb(32, 173, 3);
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
  border-radius: 20px;
}

.check-red{
  width: auto;
  padding: 10px;
  background-color: rgb(230, 25, 25);
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
  border-radius: 20px;
}

.text-check{
  font-size: 16px;
  font-weight: bolder;
}

.att-bg{
   color: cadetblue;  
}

.card4:hover{
  transform: scale(1.05);
box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

/* Attendance Managment New Dashboard Css */

.main-content-newAttendance{
  transition: all 0.2s ease-in-out;
}
.position-relative {
    position: relative !important;
  }
.table-responsive-newAttendance {
    height: auto;
    width: auto;
    -webkit-overflow-scrolling: touch;
  }
  .card-newAttendance {
   
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    border-image: linear-gradient(to right, #ec268f, #354b9c);
    border-image-slice: 1;
    border-bottom: 3px solid;
  } 
  .travel-newAttendance {
    transition: all 0.2s ease;
    cursor: pointer
}

.newAttendance-cards:hover {
    box-shadow: 5px 6px 6px 2px #e9ecef;
    transform: scale(0.9)
}

  .card-header-newAttendance {
    border-radius: 1rem 1rem 0 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0 solid rgba(0, 0, 0, 0.125);
  }
 
.border-radius-lg {
    border-radius: 0.75rem;
  }
  .row-newAttendance {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -0.5);
    margin-left: calc(var(--bs-gutter-x) * -0.5);
  }
  
  .row-newAttendance > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }

  .card-body-newAttendance {
    flex: 1 1 auto;
    padding: 1rem;
  }


  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: Eina01,sans-serif;
    font-size: 10px;
    font-weight: 500;
    justify-content: center;
    /* line-height: 10px; */
    margin: 0;
    /* min-height: 10px; */
    outline: none;
    overflow: visible;
    /* padding: 5px 5px; */
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
  }
  
  @media (min-width: 768px) {
    .button-78 {
      padding: 10px 15px;
    }
  }
  
  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }
  
  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  
  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }
  
  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }
  
  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  
  .button-78:active:not(:disabled) {
    color: #ccc;
  }
  
  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }
  
  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }
  
  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }

  .check-in {
    border-radius: 20px;
    color: rgb(255, 255, 255);
    background-color:  rgb(32, 173, 3);
    padding: 10px;
    border: none;
  }

  .check-in-red {
    border-radius: 20px;
    color: rgb(255, 255, 255);
    background-color: rgb(230, 25, 25);
    padding: 10px;
    border: none;
  }

  .eyeContt2 i{
    position: absolute;
    top: 10px;
    right: 26px;
    }
    .imgCurser img{
      cursor: default !important;
    }