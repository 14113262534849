#error {
    display: inline;
    color: red;
    margin: 5px 0 0 0;
    font-size: 10px;
  }

  #error1 {
    display: inline;
    color: red;
    margin: 5px 0 0 0;
    font-size: 10px;
  }

  #error2 {
    display: inline;
    color: red;
    margin: 5px 0 0 0;
    font-size: 10px;
  }

  #error3 {
    display: inline;
    color: red;
    margin: 5px 0 0 0;
    font-size: 10px;
  }

  #error4 {
    display: inline;
    color: red;
    margin: 5px 0 0 0;
    font-size: 10px;
  }

  #error5 {
    display: inline;
    color: red;
    margin: 5px 0 0 0;
    font-size: 10px;
  }

  #error6 {
    display: inline;
    color: red;
    margin: 5px 0 0 0;
    font-size: 10px;
  }

  #error7 {
    display: inline;
    color: red;
    margin: 5px 0 0 0;
    font-size: 10px;
  }